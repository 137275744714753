import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./NotFound.scss";
import CallToAction from "../CallToAction/CallToAction";
import Marquee from "react-fast-marquee";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-slider-wrapper">
        <Marquee speed={250} gradient={false}>
          <div className="px-arrow-right" />
          <div className="not-found-slider-text">404</div>
          <div className="px-arrow-right" />
          <div className="not-found-slider-text">Page Not Found</div>
        </Marquee>
      </div>
      <Container fluid>
        <Row className="not-found-call-to-action">
          <Col className="col-lg-6 col-12">
            <CallToAction to={"/"} label={"Homepage"} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotFound;
