import * as React from "react";
import Header from "../../Components/Header/Header";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";
import NotFound from "../../Components/NotFound/NotFound";
import Partnership from "../../Components/Partnership/Partnership";
import Footer from "../../Components/Footer/Footer";

const NotFoundPageIt = () => {
  return (
    <div>
      <Header lang="it" />
      <HamburgerMenu />
      <NotFound />
      <Partnership lang="it" />
      <Footer lang="it" />
    </div>
  );
};

export default NotFoundPageIt;
